@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

button {
  transition: background-color 0.3s;
}

button:hover {
  background-color: #ff7f50;
}

.img-rounded {
  object-fit: cover;
}
